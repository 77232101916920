import React from 'react'
import { Helmet } from 'react-helmet'

import HeroPage from '../components/hero-page'
import Layout from '../components/layout'
import BlockList from '../components/block-list'

class Mobile extends React.Component {
  render() {
    const pageTitle = 'Nos services pour téléphone cellulaire et tablette'
    const siteTitle = 'L\'Atelier Info | Nos services pour téléphone cellulaire et tablette'
    const siteDesc = 'Nous offrons des services pour téléphone cellulaire et tablette, réparations, ventes, installations, diagnostiques ...'

    const content1 = {
      title:'Services téléphone cellulaire et tablette',
      items:[
        'Réparation écran',
        'Changement de batterie',
        'Remplacement de caméra',
        'Réparation du port de rechargement',
        'Décontamination'
      ]
    }
    const content2 = {
      title:'Vente d\'appareils mobiles et accessoires',
      items:[
        'Téléphones reconditionnés',
        'Tablettes reconditionnées',
        'Câbles de charge et adaptateurs de chargeur ',
        'Écrans de protection',
        'Étuis de protection ',
        'Écouteurs'
      ]
    }
    

    return (
      <Layout location={this.props.location}>
        <div className="page-wrapper">
        <Helmet title={siteTitle}>
            <meta name="description" content={siteDesc} />
        </Helmet>
          <HeroPage title={pageTitle} imgpage="mobiles" hasBtn='Voir nos tarifs' btnHref="/tarifs"/>
          <BlockList txt={content1} circleimg={true}/>
          <BlockList txt={content2} />
        </div>
      </Layout>
    )
  }
}

export default Mobile

